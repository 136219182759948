import { get, post } from "../utils/http"

function getRecommendRestaurants(levelName, num, limit) {
    const url = "/v1/restaurant/recommend/t1";
    const data = {
        levelName,
        num,
        limit
    }
    return post({ url  , data});
}

function getRestaurantDetails(restaurantId) {
    const url = "/v1/restaurant/" + restaurantId;
    return get({ url });
}

function getRestaurantOpeningHours(restaurantId) {
    const url = "/v1/restaurant/opening-hours/" + restaurantId;
    return get({ url });
}

function getRestaurantBookingOpeningHours(restaurantId) {
    const url = "/v1/restaurant/booking-opening-hours/" + restaurantId;
    return get({ url });
}

function getRestaurantSeniorContent(restaurantId) {
    const url = "/v1/restaurant/senior-content/" + restaurantId;
    return get({ url });
}

function searchRestaurants(keyword, cityId, styleId, priceId , levelName, num, limit) {
    const url = "/v1/restaurant/s/t1";
    const data = {
        keyword,
        cityId,
        styleId,
        priceId,
        levelName,
        num,
        limit
    }
    return post({ url, data });
}

export default {
    getRecommendRestaurants,
    getRestaurantDetails,
    getRestaurantSeniorContent,
    searchRestaurants,
    getRestaurantOpeningHours,
    getRestaurantBookingOpeningHours
}