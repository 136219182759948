import { originGet } from "../utils/http"

function getConfig(configKey) {
  const url = '/system/config/configKey/' + configKey;
  return originGet({ url});
}

function isEnableEpidemic() {
  return getConfig("ec.epidemic.t1-enable")
}

function getEpidemicRestContent() {
  return getConfig("ec.epidemic.t1-rest")
}

export default {
  isEnableEpidemic,
  getConfig,
  getEpidemicRestContent
}
